<template>
  <div class="stack">
    <base-loader v-if="isRequest" class="open-ticket__loader"> </base-loader>
    <page-block v-else-if="!isRequest" style="margin-top: 0">
      <div class="stack-info__content">
        <page-title class="stack-info__title">
          {{ $t('title.server') }}
        </page-title>
        <div :class="{ visible: true }" class="stack-info__create">
          <!--          <base-button-->
          <!--            v-if="isFloatingIP !== 0"-->
          <!--            :disabled="isFloatingIP === 0"-->
          <!--            class="stack-info__create-btn"-->
          <!--            @click="newIp()"-->
          <!--          >-->
          <!--            {{ $t('newServer') }}-->
          <!--          </base-button>-->
          <base-button
            :icon="isIcon"
            :tooltip="{
              content: text,
              autoHide: false,
              placement: 'auto',
              container: false,
              trigger: 'click hover',
            }"
            :color="isColor"
            :disabled="isRequest"
            class="stack-info__create-btn"
            @click="isFloatingIP !== 0 ? newIp() : ''"
            >{{ $t('newServer') }}</base-button
          >
          <!--          <plain-button-->
          <!--            v-if="isFloatingIP === 0"-->
          <!--            v-tooltip="{-->
          <!--              content: text,-->
          <!--              autoHide: false,-->
          <!--              placement: 'top-start',-->
          <!--              container: false,-->
          <!--            }"-->
          <!--            icon="warn"-->
          <!--            size="medium"-->
          <!--            color="del"-->
          <!--            tabindex="-1"-->
          <!--            class="stack-info__create-hint"-->
          <!--          />-->
        </div>
      </div>
      <base-empty v-if="list.length === 0" title="$t('newIp.title')" class="cloud-info__empty">
        <template #link
          ><base-button>
            {{ $t('newServer') }}
          </base-button></template
        >
      </base-empty>
      <tariffs-table-floating-ip />
    </page-block>
  </div>
</template>

<script>
import TariffsTableFloatingIp from '../../Main/components/TariffsTableFloatingIp';
import BaseEmpty from '@/components/BaseEmpty/BaseEmpty';
import storeMixin from '../../../mixins/index';
import newIp from '@/mixins/newIp';
export default {
  name: 'ViewFloatingIp',
  components: {
    TariffsTableFloatingIp,
    BaseEmpty,
  },
  mixins: [storeMixin, newIp],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isRequest: true,
      diskId: '',
    };
  },
  computed: {
    isIcon() {
      return this.isFloatingIP === 0 ? 'error' : '';
    },
    isColor() {
      return this.isFloatingIP === 0 ? 'warn' : 'primary';
    },
    text() {
      if (this.isFloatingIP === 0) {
        return `Вы достигли квоты по количеству плавающих IP.<br /><br />

Перейдите в раздел <strong><a href="${localStorage.getItem('urlHash')}stack/${
          this.bmId
        }/quotas">Квоты</a></strong> для увеличения. `;
      } else {
        return 'Создать новый плавающий IP-адрес';
      }
    },
    isFloatingIP() {
      if (
        this.$store.state.moduleStack.quotasNetwork &&
        this.$store.state.moduleStack.quotasNetwork.floatingip
      ) {
        return (
          this.$store.state.moduleStack.quotasNetwork.floatingip.limit -
          this.$store.state.moduleStack.quotasNetwork.floatingip.used
        );
      } else return 0;
    },
  },
  mounted() {
    this.getOpenStackApiKey()
      .then(() => {
        this.newFetch();
      })
      .catch(error => {
        this.showError(error);
        setTimeout(() => this.$router.push({ name: 'Login' }), 400);
      });
  },
  methods: {
    getOpenStackApiKey() {
      return this.$store.dispatch('moduleStack/getOpenStackApiKey', this.id);
    },
  },
};
</script>

<i18n>
{
  "ru": {
    "title": {
      "server": "Статические плавающие IP-адреса",
      "stats": "Статистика"
    },
    "success": "Успешно выделен IP %{msg}",
    "newIp": {
      "title":"Новый плавающий IP-адрес"
    },
    "prolong": {
      "confirm" : "Сменить",
      "order": "Отмена"
    },
    "sure": {
      "confirm": "Создать"
    },
    "newServer": "Новый IP - адрес",
    "activeUnder": "Действует до {date}",
    "quotaFloatingIP": "Вы достигли квоты по количеству плавающих IP, обратитесь в поддержку для изменения квоты",
    "actions": {
      "prolong": "Продлить",
      "changePlan": "Сменить тариф"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.stack {
  display: flex;
  flex-direction: column;
  min-size(100%);
  flex: 1 1 100%;
  &-header {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb);
      //flex-direction: column;
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        //margin-right: 1.5rem;
        margin-top: 1.75rem;
        margin-bottom: 1.5rem;
        flex: 1 1 auto;
      }
    }
  }

    &-info {
    +breakpoint(sm-and-up) {
      flexy(space-betweeb);
      //flex-direction: column;
    }

    &__content {
      flexy(space-between, center);

      +breakpoint(sm-and-up) {
        justify-content: flex-start;
        //margin-right: 1.5rem;
        margin-bottom: 1.5 rem;
        flex: 1 1 auto;
      }
    }

    &__loader {
      flexy(center, center);
      flex: 1 1 auto;
    }

      &__create {
        display: flex;
        flex-direction row;
        align-content flex-end;
        align-items baseline;
        margin-top: 1.5rem;
        max-width: 200px;
        &-btn {
          margin-top: 20px
          margin-right: 0;
          +breakpoint(ms-and-up) {
            margin-top: 20px;
          }
        }
        &-hint {
          margin: -0.25rem 0.25rem;
          vertical-align: middle;
        }

        +breakpoint(sm-and-up) {
          margin: 0 0 0 auto;
        }
      }
  }

  &-mode {
    flexy(flex-end, center);
    margin-left: auto;

    +breakpoint(sm-and-up) {
      justify-content: flex-start;
      margin-left: 1.5rem;
    }

    &__btn {
      & + & {
        margin-left: 1rem;
      }
    }
  }

  &-content {
    margin-top: 1.5rem;

    +breakpoint(sm-and-up) {
      margin-top: 2rem;
    }
    +breakpoint(md-and-up) {
      margin-top: 2.5rem;
    }
  }
}
.tariff-plan {

  &__btn {
    min-width: 160px;
    & + & {
      margin-top: 14px;

      +breakpoint(sm-and-up) {
        margin-top: 0;
        margin-left: 1.5rem;
      }
    }
  }
}
</style>
